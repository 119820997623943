import Vue from 'vue';
import Router from 'vue-router';
import { menuData } from '@/constants/menuData';
// import menuId from '@/constants/menuId';
import routerPath from './routerPath';
import routerComponent from '@/router/routerComponent';
import LocalStorageManager from '@/storages/LocalStorageManager';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { checkRequiresRoleLevel } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

Vue.use(Router)

const userData = LocalStorageManager.shared.getUserData();
const mainPage = routerPath.CS_USER_BYUSERDATA_LIST;
const routesData = [
  {
    id:'HOME',
  },
  {
    id:'LOGIN',
    requiresAuth: false,
  },
  {
    id:'LOGIN',
    name:'LoginDirect',
    requiresAuth: false,
  },
  {
    id:'MY',
  },
  // --- service ---
  // 퍼핀 회원관리 > 회원관리
  {
    id:'SERVICE_USER_SEARCH_LIST',
    menu:['SERVICE', 'USER', 'SEARCH'],
    requiresMinRole:'USER',
  },
  {
    id:'SERVICE_USER_SEARCH_DETAIL',
    menu:['SERVICE', 'USER', 'SEARCH'],
    title:'회원상세',
    requiresMinRole:'USER',
  },
  // 퍼핀 회원관리 > 회원관리+
  {
    id:'SERVICE_USER_PLUS_LIST',
    menu:['SERVICE', 'USER', 'PLUS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'SERVICE_USER_PLUS_DETAIL',
    menu:['SERVICE', 'USER', 'PLUS'],
    title:'회원세부상세',
    requiresMinRole:'USER',
  },
  // 퍼핀 회원관리 > 탈퇴회원관리
  {
    id:'SERVICE_USER_LEAVE_LIST',
    menu:['SERVICE', 'USER', 'LEAVE'],
    requiresMinRole:'USER',
  },
  {
    id:'SERVICE_USER_LEAVE_DETAIL',
    menu:['SERVICE', 'USER', 'LEAVE'],
    title:'탈퇴 회원상세',
    requiresMinRole:'USER',
  },
  // 멤버십 관리 > 구매내역 조회
  {
    id:'SERVICE_MEMBERSHIP_PAYMENTS_LIST',
    menu:['SERVICE', 'MEMBERSHIP', 'PAYMENTS'],
    title:'구매내역 조회',
    requiresMinRole:'USER',
  },
  // 카드관리
  {
    id:'SERVICE_CARD_LIST',
    menu:['SERVICE', 'CARD'],
  },
  // 약관관리
  {
    id:'SERVICE_TERM_LIST',
    menu:['SERVICE', 'TERM'],
  },
  {
    id:'SERVICE_TERM_DETAIL',
    menu:['SERVICE', 'TERM'],
    title:'약관상세',
  },
  {
    id:'SERVICE_TERM_REGISTER',
    menu:['SERVICE', 'TERM'],
    title:'약관등록',
  },
  {
    id:'SERVICE_TERM_MODIFY',
    menu:['SERVICE', 'TERM'],
    title:'약관수정',
    isModify: true
  },
  {
    id:'SERVICE_POINT_INDIVIDUAL',
    menu:['SERVICE', 'POINT', 'INDIVIDUAL'],
    title:'개별 지급하기',
  },
  {
    id:'SERVICE_POINT_REWARD',
    menu:['SERVICE', 'POINT', 'REWARD'],
    title:'가족별 지급하기',
  },
  {
    id:'SERVICE_POINT_HISTORY',
    menu:['SERVICE', 'POINT', 'HISTORY'],
    title:'가족별 지급내역 조회',
  },
  {
    id:'SERVICE_BANK',
    menu:['SERVICE', 'BANK'],
    title:'입금이체 은행관리',
  },
  
  // --- cs ---
  // 회원조회
  {
    id:'CS_USER_BYUSERDATA_LIST',
    menu:['CS', 'USER', 'BYUSERDATA'],
    title:'회원정보로 회원조회',
  },
  {
    id:'CS_USER_BYBANK',
    menu:['CS', 'USER', 'BYBANK'],
    title:'은행정보로 회원조회',
  },
  {
    id:'CS_USER_BYAPPROVE',
    menu:['CS', 'USER', 'BYAPPROVE'],
    title:'결제정보로 회원조회',
  },
  {
    id:'CS_CARDRETURN_LIST',
    menu:['CS', 'CARDRETURN'],
  },
  {
    id:'CS_KBCARDPURCHASE_LIST',
    menu:['CS', 'KBCARDPURCHASE'],
  },
  {
    id:'CS_TAXRETURN_LIST',
    menu:['CS', 'TAXRETURN'],
  },
  
  // --- store ---
  // // 가맹점관리
  // {
  //   id:'STORE_STORE_LIST',
  //   menu:['STORE', 'STORE'],
  // },
  // {
  //   id:'STORE_STORE_REGISTER',
  //   menu:['STORE', 'STORE'],
  //   title:'가맹점 등록',
  // },
  // {
  //   id:'STORE_STORE_MODIFY',
  //   menu:['STORE', 'STORE'],
  //   title:'가맹점 수정',
  //   isModify: true
  // },
  // // 가맹 계약 변경요청
  // {
  //   id:'STORE_CONTRACT_LIST',
  //   menu:['STORE', 'CONTRACT'],
  //   requiresMinRole:'ADMIN',
  // },
  // // 주문 및 결제관리
  // {
  //   id:'STORE_ORDER_LIST',
  //   menu:['STORE', 'ORDER'],
  // },
  // // 개별 거래 조회
  // {
  //   id:'STORE_TRADE_LIST',
  //   menu:['STORE', 'TRADE'],
  // },
  // // 정산관리
  // {
  //   id:'STORE_SETTLE_LIST',
  //   menu:['STORE', 'SETTLE'],
  // },
  // {
  //   id:'STORE_SETTLE_DETAIL',
  //   menu:['STORE', 'SETTLE'],
  //   title:'정산 상세',
  // },
  // --- admin ---
  // 직원관리
  {
    id:'ADMIN_MEMBER_LIST',
    menu:['ADMIN', 'MEMBER'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'ADMIN_MEMBER_DETAIL',
    menu:['ADMIN', 'MEMBER'],
    title:'직원 상세',
    requiresMinRole:'ADMIN',
  },
  {
    id:'ADMIN_MEMBER_REGISTER',
    menu:['ADMIN', 'MEMBER'],
    title:'직원 등록',
    requiresMinRole:'ADMIN',
  },
  {
    id:'ADMIN_MEMBER_MODIFY',
    menu:['ADMIN', 'MEMBER'],
    title:'직원 수정',
    requiresMinRole:'ADMIN',
    isModify: true
  },
  // 시스템로그
  {
    id:'ADMIN_SYSTEM_LIST',
    menu:['ADMIN', 'SYSTEM'],
    requiresMinRole:'ADMIN',
  },
  // 데이터 조회
  {
    id:'ADMIN_DB_LIST',
    menu:['ADMIN', 'DB'],
    requiresMinRole:'ADMIN',
  },
  // --- guide ---
  {
    id:'GUIDE_COMPONETS_FORMS',
    menu:['GUIDE', 'COMPONETS', 'FORMS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_TAB',
    menu:['GUIDE', 'COMPONETS', 'TAB'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_SEARCH',
    menu:['GUIDE', 'COMPONETS', 'SEARCH'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_BOARD',
    menu:['GUIDE', 'COMPONETS', 'BOARD'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_TABLE',
    menu:['GUIDE', 'COMPONETS', 'TABLE'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_CATEGORY',
    menu:['GUIDE', 'COMPONETS', 'CATEGORY'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_CALENDAR',
    menu:['GUIDE', 'COMPONETS', 'CALENDAR'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_BUTTONS',
    menu:['GUIDE', 'COMPONETS', 'BUTTONS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_BADGES',
    menu:['GUIDE', 'COMPONETS', 'BADGES'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_WIDGETS',
    menu:['GUIDE', 'COMPONETS', 'WIDGETS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_STEP',
    menu:['GUIDE', 'COMPONETS', 'STEP'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_ICONS',
    menu:['GUIDE', 'ICONS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_CHARTS',
    menu:['GUIDE', 'CHARTS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_LAYER',
    menu:['GUIDE', 'LAYER'],
    requiresMinRole:'ADMIN',
  },
];
const routerOption = {
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: '*', redirect: mainPage },
  ]
}
setRoutesData()
const router = new Router(routerOption);
function setRoutesData(){
  routesData.forEach(itemData => {
    // console.log('------------------');
    // console.log(itemData)
    let result = {};
    const pathId = itemData.path || itemData.id;
    const name = itemData.name || itemData.id;
    const componentId = itemData.component || itemData.id;
    result.path = routerPath[pathId];
    result.name = name;
    result.component = routerComponent[componentId];
    result.meta = {};
    result.meta.isModify = itemData.isModify != undefined ? itemData.isModify : false;
    result.meta.requiresAuth = itemData.requiresAuth != undefined ? itemData.requiresAuth : true;
    result.meta.requiresMinRole = itemData.requiresMinRole;
    if(itemData.menu && itemData.menu[0]){
      const menuItem = menuData.find(menu => menu.id === itemData.menu[0]);
      result.meta.menuData = {
        id : itemData.menu[0],
        title : menuItem.title
      }
      if(itemData.menu[1]){
        const snbMenuItem = menuItem.menuList.find(snbMenu => snbMenu.id === itemData.menu[1]);
        // console.log(snbMenuItem)
        result.meta.snbMenuData = {
          id : itemData.menu[1],
          title : snbMenuItem.title
        }
        if(itemData.menu[2]){
          const lnbMenuItem = snbMenuItem.menuList.find(lnbMenu => lnbMenu.id === itemData.menu[2]);
          // console.log(lnbMenuItem)
          result.meta.lnbMenuData = {
            id : itemData.menu[2],
            title : lnbMenuItem.title
          }
        }
      }
    }
    if(itemData.title){
      result.meta.title = itemData.title;
    }
    // console.log(result)
    routerOption.routes.push(result);
  })
}

router.beforeEach((to, _from, next) => {
  const isToken = LocalStorageManager.shared.getToken();
  const userData = LocalStorageManager.shared.getUserData();
  const toRequiresAuth = to.meta.requiresAuth != false ? true : false;
  // const fromRequiresAuth = _from.meta.requiresAuth != false ? true : false;
  const requiresMinRole = to.meta.requiresMinRole;
  const requiresUser = to.meta.requiresUser;
  const authOk = isToken && userData.loginStatus === 'OK' && userData.userStatus === 'NORMAL';
  // 토큰 누락시
  if (!authOk) {
    // console.log('토큰 누락시')
    if(userData.userStatus === 'NEED_PASSWORD_CHANGE' && !store.getters['passowrdChangeError/getIsPasswordChangeError']){
      console.log('비밀번호를 변경하여주세요')
      store.dispatch('commonToast/fetchToastStart', '비밀번호를 변경하여주세요');
      store.dispatch('passowrdChangeError/fetchPasswordChangeErrorStart');
      next({ name: 'LoginDirect' });
      return;
    }
    if (to.path === routerPath.HOME) {
      store.dispatch('commonToast/fetchToastStart', '로그인 후 이용가능합니다.');
      next({ name: 'LoginDirect' });
      return;
    }
    if (toRequiresAuth) {
      store.dispatch('commonToast/fetchToastStart', '로그인 후 이용가능합니다.');
      next({ name: 'LoginDirect' });
      return;
    }
  }
  // 토큰있으면 홈 = 대시보드
  if (authOk && to.path === routerPath.HOME && _from.path != mainPage) {
    // console.log('토큰있으면 홈 = 대시보드')
    next({ path: mainPage });
    return;
  }
  // role 제어
  if(authOk && userData && requiresMinRole){
    const userRole = userData.role;
    const email = userData.email;
    const isRequiresRole = checkRequiresRoleLevel(requiresMinRole, userRole);
    const isRequiresUser = requiresUser ? requiresUser.indexOf(email) >= 0 : true;
    if(!isRequiresRole || !isRequiresUser){
      store.dispatch('commonToast/fetchToastStart', '조회 권한이 없습니다');
      next({ path: mainPage });
      return;
    }
  }
  // 동일 페이지로 이동시 막기
  if(to.path === _from.path){
    return;
  }
  next()
})

export default router
