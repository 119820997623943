const adminMenuData =
[
  {
    title: '직원 관리',
    id: 'MEMBER',
    menuIcon: '🍋',
    requiresMinRole: 'ADMIN',
    routerPath: 'ADMIN_MEMBER_LIST',
  },
  {
    title: '시스템 로그',
    id: 'SYSTEM',
    menuIcon: '🌐',
    requiresMinRole: 'ADMIN',
    routerPath: 'ADMIN_SYSTEM_LIST',
  },
  {
    title: '데이터 조회',
    id: 'DB',
    menuIcon: '🔍',
    routerPath: 'ADMIN_DB_LIST',
  },
  // {
  //   title: '마이페이지',
  //   id: 'MY',
  //   menuIcon: '⚙️',
  // },
]

export { adminMenuData }