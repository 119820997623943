import { convertDateFormat, dateDiff, dateTimeDiff,timeDiff, calcDate, getweekDay, getDayToWeek, getMonthlyDateList, getMonthLastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

const CalendarMixin = {
  data(){
    return{
      weekKoreanDataList: ['일','월','화','수','목','금','토'],
      isShowTodayMonthBtn: true,
      isDisabledTodayMonthBtn: true,
      isDisabledPrevMonthBtn: false,
      isDisabledNextMonthBtn: false,
      monthDrawDataList:[],
      activeItemDate:'',
      activeData:{},
      tooltipData:{}
    }
  },
  methods: {
    setMonthSearchDate(){
      const isLimit = this.calendarData.limit;
      const isLimitMinDate = Boolean(isLimit && isLimit.minDate);
      const isLimitMaxDate = Boolean(isLimit && isLimit.maxDate);
      const isLimitMaxMonthToday = isLimit && isLimit.isMaxMonthToday;
      if(isLimitMinDate){
        isLimit.minMonth = convertDateFormat(isLimit.minDate,'YYYY-MM')
      }
      if(isLimitMaxDate){
        isLimit.maxMonth = convertDateFormat(isLimit.maxDate,'YYYY-MM')
      }
      const isLimitMinMonth = Boolean(isLimit && isLimit.minMonth);
      const isLimitMaxMonth = Boolean(isLimit && isLimit.maxMonth);
      const thisMonth = convertDateFormat(new Date(), 'YYYY-MM');
      if(!this.calendarData.targetMonth){
        let targetMonth = thisMonth
        if(isLimitMinMonth){
          const isMinToday = dateDiff(isLimit.minMonth, thisMonth) > 0;
          if(isMinToday){
            targetMonth = isLimit.minMonth
          }
        }
        if(isLimitMaxMonth){
          const isMaxToday = dateDiff(isLimit.maxMonth, thisMonth) < 0;
          if(isMaxToday){
            targetMonth = isLimit.maxMonth
          }
        }
        this.calendarData.targetMonth = targetMonth
      }
      if(!this.activeData.date){
        this.activeData.date = convertDateFormat(new Date(), 'YYYY-MM-DD');
      }
      this.calendarData.searchDataRangeMonthly.start_date = `${this.calendarData.targetMonth}-01`;
      this.calendarData.searchDataRangeMonthly.end_date = getMonthLastDate(this.calendarData.targetMonth);
      // 컨트롤 버튼 제어
      const targetIsThisMonth = dateDiff(thisMonth, this.calendarData.targetMonth) === 0;
      let isMinToday = false;
      let isMaxToday = false;
      if( isLimitMinMonth ){
        this.isDisabledPrevMonthBtn = dateDiff(isLimit.minMonth, this.calendarData.targetMonth) >= 0;
        isMinToday = dateDiff(isLimit.minMonth, thisMonth) > 0
      }
      if(isLimitMaxMonth){
        this.isDisabledNextMonthBtn =  dateDiff(isLimit.maxMonth, this.calendarData.targetMonth) <= 0;
        isMaxToday = dateDiff(isLimit.maxDate, thisMonth) < 0
      }
      if(isLimitMaxMonthToday){
        this.isDisabledNextMonthBtn =  dateDiff(thisMonth, this.calendarData.targetMonth) === 0;
      }
      this.isShowTodayMonthBtn = !targetIsThisMonth && !( isMinToday || isMaxToday );
      this.isDisabledTodayMonthBtn = targetIsThisMonth || isMinToday || isMaxToday;

      this.setMonthData();
    },
    setMonthData(){
      // const resultDataSync = resultData || [];
      const isLimit = this.calendarData.limit;
      // const isLimitMinMonth = isLimit && isLimit.minMonth;
      // const isLimitMaxMonth = isLimit && isLimit.maxMonth;
      const isLimitMinDate = isLimit && isLimit.minDate;
      const isLimitMaxDate = isLimit && isLimit.maxDate;
      // const isLimitMaxMonthToday = isLimit && isLimit.isMaxMonthToday;
  
      this.monthDrawDataList = [];
      let monthDayList = getMonthlyDateList(this.calendarData.targetMonth);
      const firstMonthDay = this.calendarData.searchDataRangeMonthly.start_date;
      const firstSunday = getweekDay(firstMonthDay,'Sunday');
      const diffPrevDayCount = dateDiff(firstSunday,firstMonthDay);
      if(diffPrevDayCount < 0){
        const prevMonthDayList = [];
        [...Array(diffPrevDayCount*-1).keys()].forEach(prevDay => {
          prevMonthDayList.push(calcDate(firstMonthDay,((diffPrevDayCount*-1 )- prevDay)*-1,'day'));
        })
        monthDayList = prevMonthDayList.concat(monthDayList)
      }
      const lastMonthDay = this.calendarData.searchDataRangeMonthly.end_date;
      const lastSaturday = getweekDay(lastMonthDay,'Saturday');
      const diffNextDayCount = monthDayList.length - 42;
      if(diffNextDayCount < 0){
        const nextMonthDayList = [];
        [...Array(diffNextDayCount*-1).keys()].forEach(nextDay => {
          nextMonthDayList.push(calcDate(lastMonthDay,nextDay+1,'day'));
        })
        monthDayList = monthDayList.concat(nextMonthDayList)
      }
      monthDayList.forEach(day => {
        // const data = resultDataSync.find(item => item.date === day);
        // const isHasEvent = this.calendarData.calendarEvent && this.calendarData.calendarEvent.find(item => item.date === day);
        let isDisabledBefore = false;
        let isDisabledAfter = false;
        if(isLimitMinDate){
          isDisabledAfter = dateDiff(isLimit.minDate, day) > 0;
        }
        if(isLimitMaxDate){
          isDisabledBefore = dateDiff(isLimit.maxDate, day) < 0;
        }
        const week = getDayToWeek(day);
        var obj = {};
        obj.date =  convertDateFormat(day,'DD');
        obj.fullDate = day;
        obj.isToday = dateDiff(new Date(), day) === 0;
        obj.isDisabled = isDisabledBefore || isDisabledAfter;
        obj.isPrevMonthDay = convertDateFormat(day,'MM') < convertDateFormat(this.calendarData.targetMonth,'MM');
        obj.isNextMonthDay = convertDateFormat(day,'MM') > convertDateFormat(this.calendarData.targetMonth,'MM');
        obj.data = {};
        obj.week = week;
        obj.shcedule = null;
        obj.shceduleRange = { rangeRowCount:0, scheduleDataList: [] };
        // obj.event = isHasEvent || '';
        obj.isWeekend = week === '토' || week === '일'
        this.monthDrawDataList.push(obj)
      })
    },
    setData(){
      if(( !this.dataList || this.dataList.length === 0 ) && ( !this.scheduleList || this.scheduleList.length === 0 ) && ( !this.scheduleRangeList || this.scheduleRangeList.length === 0 ) ) return;
      if((this.dataList && this.dataList.length > 0) || (this.scheduleList && this.scheduleList.length > 0)){
        const hasActvieData = this.scheduleList.find(item => item.date === this.activeData.date);
        if(this.scheduleList && !hasActvieData){
          this.activeData = {};
        }
        this.monthDrawDataList.forEach((monthDrawData,index) => {
          if(this.dataList){
            const dataIndex = this.dataList.findIndex(item => item.date === monthDrawData.fullDate);
            if(dataIndex > -1){
              monthDrawData.data = this.dataList[dataIndex];
            }else{
              monthDrawData.data = {};
            }
          }
          if(this.scheduleList){
            const scheduleIndex = this.scheduleList.findIndex(item => item.date === monthDrawData.fullDate);
            if(scheduleIndex > -1){
              monthDrawData.shcedule = this.scheduleList[scheduleIndex];
              if(this.activeData.date === monthDrawData.fullDate){
                this.activeData.scheduleDataList = this.scheduleList[scheduleIndex].scheduleDataList;
              }
            }else{
              monthDrawData.shcedule = null;
            }
          }
        })
      }else if(this.scheduleRangeList && this.scheduleRangeList.length > 0){
        // console.log(convertDateFormat(this.scheduleRangeList[0].startAt, 'YYYY-MM-DD'))
        // const hasActvieData = this.scheduleRangeList.find(item => convertDateFormat(item.startAt, 'YYYY-MM-DD') === this.activeData.date);
        // if(this.scheduleRangeList && !hasActvieData){
        //   this.activeData = {};
        // }

        this.scheduleRangeList.sort(function (a, b) {
          return dateDiff(a.startAt,b.startAt) + dateTimeDiff(a.startAt,b.startAt) + dateDiff(a.endAt,b.endAt) + dateTimeDiff(a.endAt,b.endAt)
        });
        const mounthStartAt = this.monthDrawDataList[0].fullDate;
        const mounthEndAt = this.monthDrawDataList[this.monthDrawDataList.length-1].fullDate;
        this.scheduleRangeList.forEach((scheduleRangeItem,index) => {
          const timeRequired = dateTimeDiff(scheduleRangeItem.endAt,scheduleRangeItem.startAt);
          scheduleRangeItem.isStartRange = false;
          scheduleRangeItem.isBetweenRange = false;
          scheduleRangeItem.isEndRange = false;
          scheduleRangeItem.timeRequired = timeRequired;

          let startAt = scheduleRangeItem.startAt;
          let endAt = scheduleRangeItem.endAt;
          const diffStartMount = dateDiff(startAt,mounthStartAt);
          const diffEndMount = dateDiff(mounthEndAt,endAt);
          if(diffStartMount < 0){
            startAt = mounthStartAt;
          }
          if(diffEndMount < 0){
            endAt = mounthEndAt;
          }
          const rangeDayCount = dateDiff(endAt,startAt) + 1;

          let standardDate = convertDateFormat(startAt, 'YYYY-MM-DD');
          const monthDrawDataIndex = this.monthDrawDataList.findIndex(item => item.fullDate === standardDate)
          for(var i = 0; i < rangeDayCount; ++i){
            let pushData = Object.assign({},scheduleRangeItem);
            const isWeekStart = getDayToWeek(this.monthDrawDataList[monthDrawDataIndex+i].fullDate);
            pushData.isStartRange = Boolean(i === 0);
            pushData.isBetweenRange = Boolean(i > 0 && i < rangeDayCount-1);
            pushData.isEndRange = Boolean(i === rangeDayCount-1);
            pushData.diffRow = 0;
            let startRow = 0;
            let overList = [];
            let scheduleDatList = this.monthDrawDataList[monthDrawDataIndex+i].shceduleRange.scheduleDataList
            if(pushData.isStartRange || isWeekStart === '일'){
              const scheduleDatListLength = scheduleDatList.length;
              overList = scheduleDatList.filter((item,index) => {
                return item.startRow != index;
              })
              // const 
              scheduleRangeItem.startRow = scheduleDatListLength - overList.length || 0;
            }
            pushData.startRow = scheduleRangeItem.startRow || startRow;
            scheduleDatList.push(pushData);
            scheduleDatList.sort(function (a, b){
              return a.startRow - b.startRow
            });
            scheduleDatList.forEach((item,index) => {
              if(index === 0){
                item.marginTop = item.startRow*24 ? item.startRow*24 : 0
              }else if(index != 0){
                if(item.startRow - scheduleDatList[index-1].startRow != 1){
                  item.marginTop = ((item.startRow - scheduleDatList[index-1].startRow-1)*24)+3;
                }else{
                  item.marginTop = 3
                }
              }
            })
          }
        })
      }

    },
    onClickPrevMonth(){
      this.calendarData.targetMonth = calcDate(this.calendarData.targetMonth,-1,'month','YYYY-MM');
      this.resetEvent();
      this.setMonthSearchDate();
      this.setMonthData();
      this.$emit('getData');
    },
    onClickNextMonth(){
      this.calendarData.targetMonth = calcDate(this.calendarData.targetMonth,1,'month','YYYY-MM');
      this.resetEvent();
      this.setMonthSearchDate();
      this.setMonthData();
      this.$emit('getData');
    },
    onClickTodayMonth(){
      this.calendarData.targetMonth = convertDateFormat(new Date(),'YYYY-MM');
      this.resetEvent();
      this.setMonthSearchDate();
      this.setMonthData();
      this.$emit('getData');
    },
    onAddSchedule(date){
      const today = convertDateFormat(new Date(), 'YYYY-MM-DD');
      const addDate = date || this.activeData.date || today;
      this.activeDat = {};
      this.activeData.date = addDate;
      this.$emit('onAddSchedule',addDate);
    },
    onClickItem(data){
      this.activeData.date = data.date;
      this.activeData = data;
      window.addEventListener('click',((e) => this.resetEvent(e)),true)
    },
    onClickScheduleItem(data){
      this.tooltipData = data;
      window.addEventListener('click',((e) => this.resetEvent(e)),true)
    },
    resetEvent(e){
      const isClickTooltip = e && e.path && e.path.findIndex(item => item.id === 'tooltipEvent') > -1;
      const isClickSchedule = e && e.path && e.path.findIndex(item => item.id === 'scheduleTarget') > -1;
      const isClickFootSchedule = e && e.path && e.path.findIndex(item => item.id === 'btnSchedule') > -1;
      const isClickschedulePopup = e && e.path && e.path.findIndex(item => item.id === 'lemontreeLayer') > -1;
      if(!e || ( e.path && !isClickTooltip && !isClickSchedule && !isClickFootSchedule && !isClickschedulePopup )){
        const today = convertDateFormat(new Date(), 'YYYY-MM-DD');
        const todayMonth = convertDateFormat(new Date(), 'YYYY-MM');
        const activeDataMonth = convertDateFormat(this.activeData.date, 'YYYY-MM');
        const targetFirstDay = this.calendarData.targetMonth+'-01';
        const targetMonthActive = activeDataMonth === this.calendarData.targetMonth;
        const todayMonthActive = todayMonth === this.calendarData.targetMonth;
        if(!targetMonthActive){
          let hasActvieData = {};
          if(this.scheduleList){
            hasActvieData = this.scheduleList.find(item => item.date === this.activeData.date)
          }
          if(this.scheduleRangeList){
            hasActvieData = this.scheduleRangeList.find(item => item.date === this.activeData.date)
          }
          this.activeDat = {};
          if(hasActvieData){
            this.activeData = hasActvieData;
          }else{
            this.activeData.date = todayMonthActive ? today : targetFirstDay;
          }
        }
        this.tooltipData = {};
      }
    },
  }
}
export default CalendarMixin;