const csMenuData =
[
  {
    title: '회원조회',
    id: 'USER',
    menuIcon: '🧐',
    menuList: [ 
      {
        title: '회원정보로 조회',
        id: 'BYUSERDATA',
        requiresMinRole: 'ADMIN',
        routerPath: 'CS_USER_BYUSERDATA_LIST',
      },
      {
        title: '은행정보로 조회',
        id: 'BYBANK',
        requiresMinRole: 'ADMIN',
        routerPath: 'CS_USER_BYBANK',
      },
      {
        title: '결제정보로 조회',
        id: 'BYAPPROVE',
        requiresMinRole: 'ADMIN',
        routerPath: 'CS_USER_BYAPPROVE',
      },
    ]
  },
  {
    title: '카드 반송 조회',
    id: 'CARDRETURN',
    menuIcon: '♻️',
    routerPath: 'CS_CARDRETURN_LIST',
  },
  {
    title: '매입취소',
    id: 'KBCARDPURCHASE',
    menuIcon: '⛔️',
    routerPath: 'CS_KBCARDPURCHASE_LIST',
  },
  {
    title: '소득공제 신청자 조회',
    id: 'TAXRETURN',
    menuIcon: '💸',
    routerPath: 'CS_TAXRETURN_LIST',
  },
]

export { csMenuData }