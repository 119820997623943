import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router/'
import LocalStorageManager from '@/storages/LocalStorageManager';

export default class CheckSessionViewModel {
  constructor() {
  }
  checkSession(){
    this.getMe();
  }
  getMe(){
    const path = `${apiPath.ME}`;
    GET_REQUEST(path).then(
    (success) => {
      // 기존 세션 살아있음
    }, (failed) => {
      // 세션 만료
      store.dispatch('commonToast/fetchToastStart', failed.msg);
      router.push({ name : "LoginDirect" }).catch(()=>{});
      LocalStorageManager.shared.clearToken();
      LocalStorageManager.shared.clearUserData();
      store.dispatch('commonUserData/fetchUserData', {});
    })
  }
}