// (더미)상태
export const dummyStatusDataList = [
  { id:'TODO', text:'할일' },
  { id:'DOING', text:'진행중' },
  { id:'DONE', text:'완료' },
  { id:'HOLD', text:'보류' },
]

// (더미)상태2
export const dummy2StatusDataList = [
  { id:'High', text:'높음' },
  { id:'Normal', text:'보통' },
  { id:'Light', text:'낮음' },
  { id:'None', text:'없음' },
]
export const dummy_status2_icon = [
  { id:'High', text:'arrow_top' },
  { id:'Normal', text:'drag' },
  { id:'Light', text:'arrow_bottom' },
  { id:'None', text:'cross' },
]

// 공통
export const common_boolean = [
  { id:true, text:'Y' },
  { id:false, text:'N' },
]
export const common_cs_status = [
  { id:'REGERVVATION', text:'접수' },
  { id:'ING', text:'응대중' },
  { id:'COMPLETE', text:'완료' },
]

// 서비스 > 회원관리
export const user_role = [
  { id:'PARENTS', text:'부모' },
  { id:'CHILD', text:'자녀' },
]
export const user_role_detail = [
  { id:'CHILD_UNDER_FOURTEEN', text:'14세미만 자녀회원' },
  { id:'CHILD', text:'14세이상 자녀회원' },
  { id:'PARENT', text:'부모회원' },
]
export const user_family_leader = [
  { id:'LEADER', text:' (대표)' },
  { id:'MEMBER', text:'' },
]
export const user_status_name = [
  // { id:'ready', text:'대기중' },
  // { id:'active', text:'활성화' },
  // { id:'quit', text:'탈퇴' },
  // { id:'inacitve', text:'휴면' },
  // { id:'restricted', text:'제재' },
  { id:'PENDING', text:'초대중' },
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
  { id:'EXPIRED', text:'만료' },
]
// 서비스 > 카드 관리
export const card_type = [
  { id:'NEW', text:'신규발급' },
  { id:'REAPPLY', text:'재발급' },
]
export const card_status = [
  { id:'WAIT', text:'발급 신청' },
  { id:'CANCELED_WAITING', text:'발급 취소 요청' },
  { id:'CANCELED', text:'발급 취소' },
  { id:'REGISTER_WAITING', text:'카드 등록 요청' },
  { id:'REGISTERED', text:'카드 등록' },
  { id:'UNREGISTER_WAITING', text:'카드 해지 요청' },
  { id:'UNREGISTERED', text:'카드 해지' },
  { id:'SUSPENDED', text:'카드 정지' },
  { id:'EXPIRED', text:'카드 만료' },
]
export const card_apply_status = [
  { id:'WRITING', text:'신청서 작성중' },
  { id:'EXPIRED', text:'신청서 기간 만료' },
  { id:'CONFIRM', text:'신청서 작성완료' },
  { id:'INVALIDATION_DELETE', text:'신청서 무효화' },
  { id:'WAIT', text:'신청 요청' },
  { id:'CANCELED', text:'발급 취소' },
  { id:'REGISTERED', text:'카드 등록' },
  { id:'UNREGISTERED', text:'해지 요청' },
]

// 서비스 > 멤버십관리
export const membership_type = [
  { id:'PLUS_USER', text:'플러스' },
  { id:'BANK_ASSOCIATION', text:'은행연합회' },
  { id:'ECONOMY_NEWS', text:'경제뉴스' },
  { id:'DLAB', text:'디랩' },
  { id:'DONGA_SCIENCE', text:'동아사이언스' },
]

export const membership_id_dev = [
  { id:'1', text:'은행연합회' },
  { id:'2', text:'플러스 12개월' },
  { id:'8', text:'플러스 11개월' },
  { id:'4', text:'플러스 6개월' },
  { id:'7', text:'플러스 5개월' },
  { id:'3', text:'플러스 3개월' },
  { id:'6', text:'플러스 1개월' },
  { id:'5', text:'플러스 1일' },
  { id:'9', text:'경제뉴스' },
]

export const membership_id_prod = [
  { id:'1', text:'은행연합회' },
  { id:'2', text:'플러스 12개월' },
  { id:'8', text:'플러스 11개월' },
  { id:'7', text:'플러스 5개월' },
  { id:'3', text:'플러스 1개월' },
]

export const membership_purchase_method_type = [
  { id:'FREE', text:'무료' },
  { id:'REDEEM', text:'리딤' },
  { id:'APPLE', text:'apple' },
  { id:'GOOGLE', text:'google' },
  { id:'IMWEB', text:'아임웹' },
]

export const membership_payment_status = [
  { id:'PAYMENT', text:'완료' },
  { id:'CANCEL', text:'취소' },
]

// 서비스 > 약관 관리
export const term_status = [
  { id: 'REGIVATION', text:'배포예약' },
  { id: 'TEMP', text:'임시저장' },
  { id: 'DEPLOIED', text:'배포완료' },
]
export const term_status_icon = [
  { id: 'REGIVATION', text:'🕓' },
  { id: 'TEMP', text:'🛠' },
  { id: 'DEPLOIED', text:'✅' },
]

// 서비스 > 포인트 지급관리
export const point_transaction_status = [
  { id: 'LOAD_FAIL', text:'🚫 로드실패' },
  { id: 'LOAD', text:'✔️ 로드성공' },
  { id: 'REGISTERED', text:'✅ 지급완료' },
  	
]

// 서비스 > 입금이체 은행관리
export const bank_status = [
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
]

// 앱관리 > 알림
export const alarm_test = [
  { id:true, text:'테스트' },
  { id:false, text:'N' },
]
export const alarm_test_register = [
  { id:true, text:'테스트 발송' },
  { id:false, text:'실 유저에게 발송' },
]
export const alarm_ad = [
  { id:true, text:'광고' },
  { id:false, text:'N' },
]
export const alarm_ad_register = [
  { id:true, text:'광고' },
  { id:false, text:'광고아님' },
]
export const alarm_platform = [
  { id:'ALL', text:'ALL' },
  { id:'IOS', text:'iOS' },
  { id:'ANDROID', text:'Android' },
]
export const alarm_audience = [
  { id:'ALL', text:'전체' },
  { id:'PARENT_ONLY', text:'부모' },
  { id:'CHILD_ONLY', text:'자녀' },
]
export const alarm_chanel = [
  { id:1, text:'🍋 레몬트리 가이드' },
  { id:2, text:'💳 레몬트리 카드' },
  { id:3, text:'🏙 레몬시티' },
  { id:4, text:'🩺 레몬코치' },
]
export const alarm_status = [
  { id:'REGERVVATION', text:'발송예약' },
  { id:'ING', text:'발송중' },
  { id:'COMPLETE', text:'발송완료' },
]

// ---- Cs Tool > 회원조회 ----
export const cs_family_status = [
  { id:'REGISTERED', text:'등록중' },
  { id:'UNREGISTERED', text:'가족탈퇴' },
  { id:'PENDING', text:'보류유저' },
  { id:'IMPOSSIBLE', text:'불가능멤버' },
  { id:'DENY', text:'초대장거절' },
]
// 카드 재배송 > 주소
export const cs_address_cagetory = [
  { id:'HOME', text:'집' },
  { id:'ETC', text:'기타' },
]
// 카드 디자인 변경
export const cs_card_design_type = [
  { id:'BLACK_V1', text:'내추럴 블랙' },
  { id:'PURPLE_V1', text:'프리미엄 퍼플' },
]
// 입출금 내역 > 거래타입
export const cs_transaction_type = [
  { id:'WITHDRAW', text:'인출' },
  { id:'DEPOSIT', text:'예금' },
]
// 은행(쿠콘) 입출금 내역 > 거래상태
export const cs_transaction_status = [
  { id:'REGISTERED', text:'성공' },
  { id:'UNREGISTERED', text:'실패' },
]
// 카드 사용 내역 > 거래타입
export const cs_card_transaction_type = [
  { id:'PAYMENT', text:'결제' },
  { id:'CANCEL', text:'취소' },
]
export const cs_trans_label = [
  { id:'EXPORT', text:'출금' },
  { id:'IMPORT', text:'입금' },
]
// 가상계좌 발급내역 > 가상계죄상태
export const cs_virtual_account_status = [ 
  { id:'REGISTERED', text:'등록완료' },
  { id:'UNREGISTERED', text:'등록해지' },
  { id:'SUSPENDED', text:'사용중지' },
  { id:'BLOCKED', text:'사용차단' },
]
// 바코드 결제내역 > 트랜잭션 타입
export const cs_barcode_transaction_type = [
  { id:'PAYMENT', text:'결제승인' },
  { id:'CANCEL', text:'결제취소' },
  { id:'ONLINE_CANCEL', text:'망취소' },
]
// 카드 재배송 확인 > 재배송 / 폐기
export const cs_re_delivery_type = [
  { id:'RE_DELIVERY', text:'재배송' },
  { id:'DISCARD', text:'폐기' },
]
// 가상계좌 거래내역 > 거래상태
export const cs_response_type = [
  { id:'FAIL', text:'실패' },
  { id:'SUCCESS', text:'성공' },
]
// 카드 재배송 확인 > 상태
export const cs_response_status = [
  { id:'RE_DELIVERY_REQUEST', text:'재발송 요청' },
  { id:'RE_DELIVERY_RESPONSE', text:'재발송 응답' },
  { id:'DISCARD_REQUEST', text:'폐기 요청' },
  { id:'DISCARD_RESPONSE', text:'폐기 응답' },
]
// 소득공제 신청자 목록 > 상태
export const cs_taxreturn_status = [
  { id:'USER_REQUEST', text:'신청완료' },
  { id:'PUBLISHING', text:'KB요청' },
  { id:'SENDING', text:'PDF변환' },
  { id:'COMPLETE', text:'우편발송' }
]
// 카드 취소/해지 > 해지된 카드/신청서 상태변경
export const cs_card_update_free_cancel_reason = [
  { id:'DAMAGE_CARD', text:'카드손상' },
  { id:'CONTACT_CUSTOMER', text:'고객요청' },
  { id:'EVENT', text:'이벤트' },
  { id:'DELAY_DELIVERY', text:'배송지연' },
  { id:'ETC', text:'기타' },
]
// 카드 취소/해지 > 카드 정지/정지해제
export const cs_card_suspend_type = [
  { id:'USER_REQUEST', text:'유저요청' },
  { id:'BLACK_USER', text:'블랙 리스트' },
  { id:'CARD_LOST', text:'카드분실' },
  { id:'FRAUD_DETECTION', text:'이상거래' },
  { id:'ETC', text:'기타' }
]
// 송금/결제한도 상태
export const cs_transaction_limit_status = [
  { id:'NONE', text:'미설정' },
  { id:'REGISTERED', text:'제한적용중' },
  { id:'UNREGISTERED', text:'제한해제' },
]

// 가맹점 관리 > 공통
export const account_bank = [
  { id: 'IBK기업은행', text:'IBK기업은행' },
  { id: 'SC제일은행', text:'SC제일은행' },
  { id: '경남은행', text:'경남은행' },
  { id: '광주은행', text:'광주은행' },
  { id: '국민은행', text:'국민은행' },
  { id: '농협', text:'농협' },
  { id: '대구은행', text:'대구은행' },
  { id: '부산은행', text:'부산은행' },
  { id: '신한은행', text:'신한은행' },
  { id: '외환은행', text:'외환은행' },
  { id: '우리은행', text:'우리은행' },
  { id: '우체국', text:'우체국' },
  { id: '하나은행', text:'하나은행' },
  { id: '한국씨티은행', text:'한국씨티은행' },
  { id: '카카오뱅크', text:'카카오뱅크' },
  // { id: 'IBKOKRSE', text:'IBK기업은행' },
  // { id: 'SCBLKRSE', text:'SC제일은행' },
  // { id: 'KYNAKR22XXX', text:'경남은행' },
  // { id: 'KWABKRSE', text:'광주은행' },
  // { id: 'CZNBKRSE', text:'국민은행' },
  // { id: 'NACFKRSEXXX', text:'농협' },
  // { id: 'DAEBKR22', text:'대구은행' },
  // { id: 'PUCBKR2P', text:'부산은행' },
  // { id: 'SHBKKRSE', text:'신한은행' },
  // { id: 'KOEXKRSEXX', text:'외환은행' },
  // { id: 'HVBKKRSEXXX', text:'우리은행' },
  // { id: 'SHBKKRSEKPO', text:'우체국' },
  // { id: 'HNBNKRSE', text:'하나은행' },
  // { id: 'CITIKRSX', text:'한국씨티은행' },
  // { id: 'CITIKRSXKAK', text:'카카오뱅크' },
]

// 가맹점 관리 > 가맹 계약 변경요청
export const contract_type = [
  { id: 'REQUESTED', text:'요청' },
  { id: 'APPROVED', text:'승인' },
  { id: 'REJECTED', text:'거부' },
]

// 가맹점 관리 > 개별 거래 조회
export const trade_type = [
  { id: 'READY', text:'결제대기' },
  { id: 'INFO', text:'결제중' },
  { id: 'APPROVE', text:'결제완료' },
  { id: 'CONFIRM', text:'결제성공' },
  { id: 'ABORTED', text:'결제취소' },
  { id: 'CANCELED', text:'결제취소' },
  { id: 'EXPIRED', text:'기간만료' },
]

// 가맹점 관리 > 정산 관리
export const settle_step = [
  { id: 'REQUEST', text:'정산요청' },
  { id: 'PENDING', text:'정산대기' },
  { id: 'DONE', text:'정산완료' },
]
export const settle_status = [
  { id:'UNPAID', text:'미정산' },
  { id:'PAID', text:'정산' },
]
export const settled_type = [
  { id:'RECEIPT', text:'입금' },
  { id:'CARRY', text:'이월' },
  { id:'REFUND', text:'반환' },
]
export const settled_done_type = [
  { id:'RECEIPT', text:'입금완료' },
  { id:'CARRY', text:'정산이월' },
  { id:'REFUND', text:'반환확인완료' },
]
export const settle_cycle_type = [
  { id:'WEEK', text:'주단위 정산' },
  { id:'MONTH', text:'월단위 정산' },
]
export const settle_receipt_delay = [
  { id:'1', text:'정산금액 소액입니다. 다음 회차 정산금 입금시 함께 입금할 예정입니다.' },
  { id:'2', text:'받는 분 계좌오류입니다. 정산계좌를 확인해 주세요.' },
  { id:'3', text:'시스템 오류로 지연중입니다. 불편하시더라도 조금만 기다려주세요.' },
  // { id:'0', text:'직접입력' },
]

// 가맹점 관리 > 가맹점 관리
export const store_status = [
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
]
export const store_on_off = [
  { id:'ONLINE', text:'온라인' },
  { id:'OFFLINE', text:'오프라인' },
]
export const store_company_type = [
  { id:'INDIVIDUAL', text:'개인' },
  { id:'COMPANY', text:'법인' },
  { id:'NONPROFIT', text:'비영리법인' },
]
export const store_tax_business_type = [
  { id:'TAXATION', text:'복합과세' },
  { id:'taxable', text:'과세' },
  { id:'duty_free', text:'면세' },
  { id:'zero_tax_rate', text:'영세율' },
]
export const store_manager_type = [
  { id:'ORDER_MANAGER', text:'계약담당자' },
  { id:'TECH_MANAGER', text:'기술담당자' },
  { id:'SETTLE_MANAGER', text:'정산담당자' },
]

// 주문 및 결제관리
export const order_status = [
  { id: 'CONFIRM', text:'결제완료' },
  { id: 'CANCELED', text:'전액취소' },
  { id: 'EXPIRED', text:'결제만료' },
  { id: 'ABORTED', text:'결제취소' },
]

// 직원관리 > 회원 권한
export const member_role = [
  { id:'ADMIN', text:'관리자' },
  { id:'USER', text:'일반' },
]

// 직원관리 > 회원 로그인 상태
export const member_login_status = [
  { id:'OK', text:'정상 로그인' },
  { id:'LOCKED', text:'로그인 잠금' },
  { id:'NEED_MFA', text:'MFA 확인 필요' },
]

// 직원관리 > 회원 로그인 상태 아이콘
export const member_login_status_icon = [
  { id:'OK', text:'login_ok' },
  { id:'LOCKED', text:'login_lock' },
  { id:'NEED_MFA', text:'login_setting' },
]

// 스케줄 타입 // APP, BANK, CONTENT, PAGE
export const scheduleTypeDataList = [
  { id:'GENERAL', text:'공통' },
  { id:'CONTENT', text:'컨텐츠' },
  { id:'APP', text:'App' },
  { id:'PAGE', text:'Landing Page' },
  { id:'BANK', text:'은행' },
]

export const selectDataList = {
  // ** (더미)상태
  dummy_status : dummyStatusDataList,
  dummy_status2 : dummy2StatusDataList,
  dummy_status2_icon : dummy_status2_icon,

  common_boolean : common_boolean,
  common_cs_status: common_cs_status,

  user_role : user_role,
  user_role_detail : user_role_detail,
  user_family_leader : user_family_leader,
  user_status_name : user_status_name,

  card_type : card_type,
  card_status : card_status,
  card_apply_status : card_apply_status,

  membership_type : membership_type,
  membership_id_dev : membership_id_dev,
  membership_id_prod : membership_id_prod,
  membership_purchase_method_type : membership_purchase_method_type,
  membership_payment_status : membership_payment_status,

  term_status : term_status,
  term_status_icon  : term_status_icon,

  point_transaction_status : point_transaction_status,

  bank_status : bank_status,

  alarm_test : alarm_test,
  alarm_test_register : alarm_test_register,
  alarm_ad : alarm_ad,
  alarm_ad_register : alarm_ad_register,
  alarm_platform : alarm_platform,
  alarm_audience : alarm_audience,
  alarm_chanel : alarm_chanel,
  alarm_status : alarm_status,

  account_bank : account_bank,

  contract_type : contract_type,
  
  trade_type : trade_type,

  settle_step : settle_step,
  settle_status : settle_status,
  settled_type : settled_type,
  settled_done_type : settled_done_type,
  settle_cycle_type : settle_cycle_type,
  settle_receipt_delay : settle_receipt_delay,

  cs_family_status : cs_family_status,
  cs_address_cagetory : cs_address_cagetory,
  cs_card_design_type : cs_card_design_type,
  cs_transaction_type : cs_transaction_type,
  cs_transaction_status : cs_transaction_status,
  cs_card_transaction_type : cs_card_transaction_type,
  cs_trans_label : cs_trans_label,
  cs_virtual_account_status : cs_virtual_account_status,
  cs_barcode_transaction_type: cs_barcode_transaction_type,
  cs_re_delivery_type : cs_re_delivery_type,
  cs_response_type : cs_response_type,
  cs_response_status : cs_response_status,

  cs_taxreturn_status : cs_taxreturn_status,
  cs_card_update_free_cancel_reason : cs_card_update_free_cancel_reason,
  cs_card_suspend_type : cs_card_suspend_type,
  cs_transaction_limit_status : cs_transaction_limit_status,

  store_status : store_status,
  store_on_off : store_on_off,
  store_company_type : store_company_type,
  store_tax_business_type : store_tax_business_type,
  store_manager_type : store_manager_type,

  order_status : order_status,

  member_role : member_role, // 회원 권한
  member_login_status : member_login_status, // 회원 로그인 상태
  member_login_status_icon : member_login_status_icon, // 회원 로그인 상태

  schedule_type: scheduleTypeDataList, // 스케줄 타입
}