import NewPasswordModel from '@/views/my/model/NewPasswordModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { groupEncryptWithRSA } from '@lemontree-ai/lemontree-admin-common-front/utils/RsaUtils';
import { POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class NewPasswordViewModel {
  constructor() {
    this.model = {};
    this.isPasswordChangePopup = false;
    this.passwordChangeValid = {
      oldPassword:{
        value: 'oldPassword',
        valueType:'password',
        isValid:false,
        validAlertDesc: '대문자, 소문자, 특수문자, 숫자를 조합하여 8자이상 입력해주세요',
      },
      newPassword:{
        value: 'newPassword',
        valueType:'password',
        unReferenceValue:'oldPassword',
        isValid:false,
        validAlertDesc: '대문자, 소문자, 특수문자, 숫자를 조합하여 8자이상 입력해주세요',
        referenceVaildAlertDesc: '현재 비밀번호와 동일한 비밀번호를 입력하셨습니다',
      },
      newRepeatPassword:{
        value: 'newRepeatPassword',
        valueType:'password_confirm',
        referenceValue:'newPassword',
        isValid:false,
        validAlertDesc: '대문자, 소문자, 특수문자, 숫자를 조합하여 8자이상 입력해주세요',
        referenceVaildAlertDesc: '입력하신 변경할 비밀번호와 동일하게 입력해주세요',
      }
    }
    this.doneToastMsg = '비밀번호가 변경되었습니다.'
  }

  init(){
    this.model = new NewPasswordModel();
  }

  passwordChangeValidatedResult(){
    return pageValidated(this.passwordChangeValid, this.model)
  }

  onClickPasswordChangePopup(){
    this.isPasswordChangePopup = true;
  }
  onClickClosePasswordChangePopup(){
    this.isPasswordChangePopup = false;
  }
  onClickCompletePasswordChangePopup(){
    this.setPassword();
  }
  setPassword(){
    const rsaData = {
      oldPassword : this.model.oldPassword,
      newPassword : this.model.newPassword,
      newRepeatPassword : this.model.newRepeatPassword,
    }
    groupEncryptWithRSA(rsaData).then((result) => {
      this.model.rsaPasswordData = result;
      this.postChangePassword();
    });
  }
  postChangePassword(){
    const path = `${apiPath.CHANGE_PASSWORD}`;
    const data = this.model.getData();
    console.log(data);
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonUserData/fetchUserData', resultData);
      
      store.dispatch('commonToast/fetchToastStart', this.doneToastMsg);
      store.dispatch('passowrdChangeError/fetchPasswordChangeErrorEnd');
      this.isPasswordChangePopup = false;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}