import Vue from 'vue'
import App from './App.vue'
import router from './router/';
import routerPath from './router/routerPath.js';
import apiPath from './services/apiPath';

// adminPackage
import '@lemontree-ai/lemontree-admin-common-front/filters/index';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

import colorSass from '@/assets/scss/color.scss';
import { commonData } from '@/constants/commonData';

Vue.config.productionTip = false;

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

Vue.prototype.$routerPath = routerPath;
Vue.prototype.$apiPath = apiPath;

// adminPackage
Vue.prototype.$commonData = commonData;
Vue.prototype.$store = store;
Vue.prototype.$colorSass = colorSass;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
