<template>
  <Popup
    :title="title"
    :isLayer="true"
    :maxWidth="700"
    :subTitle="subTitle"
    :closeBtnText="closeBtnText"
    :compeleteBtnText="compeleteBtnText"
    :isDisabeldCompeleteBtn="isValid"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete')">
    <TableView>
      <template v-if="isNeedChange" v-slot:tbl_top>
        <p class="desc_password">안전한 사용을 위하여,<br><em class="tc_primary">첫 가입 시와 변경 후 90일 이후에는 비밀번호를 변경</em>해야 합니다.<br>아래에 새 비밀번호를 입력해 주세요.</p>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>현재 비밀번호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="현재 비밀번호"
              type="password"
              :value.sync="viewModel.model.oldPassword"
              :valueType="viewModel.passwordChangeValid.oldPassword.valueType"
              :isError.sync="viewModel.passwordChangeValid.oldPassword.isValid"
              :errorDesc="viewModel.passwordChangeValid.oldPassword.validAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>변경할 비밀번호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="변경할 비밀번호"
              type="password"
              :value.sync="viewModel.model.newPassword"
              :unReferenceValue="viewModel.model[viewModel.passwordChangeValid.newPassword.unReferenceValue]"
              :valueType="viewModel.passwordChangeValid.newPassword.valueType"
              :isError.sync="viewModel.passwordChangeValid.newPassword.isValid"
              :errorDesc="viewModel.passwordChangeValid.newPassword.validAlertDesc"
              :referenceErrorDesc="viewModel.passwordChangeValid.newPassword.referenceVaildAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>변경할 비밀번호 확인<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="변경할 비밀번호 확인"
              type="password"
              :value.sync="viewModel.model.newRepeatPassword"
              :referenceValue="viewModel.model[viewModel.passwordChangeValid.newRepeatPassword.referenceValue]"
              :valueType="viewModel.passwordChangeValid.newRepeatPassword.valueType"
              :isError.sync="viewModel.passwordChangeValid.newRepeatPassword.isValid"
              :errorDesc="viewModel.passwordChangeValid.newRepeatPassword.validAlertDesc"
              :referenceErrorDesc="viewModel.passwordChangeValid.newRepeatPassword.referenceVaildAlertDesc"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';

export default {
  name:'PasswordChangePopup',
  components:{
    Popup,
    TableView,
    Input,
    IconSvg
  },
  props:{
    viewModel: Object,
    title: String,
    subTitle: String,
    closeBtnText: String,
    compeleteBtnText: String,
    isNeedChange:Boolean,
  },
  beforeMount(){
    this.viewModel.init()
  },
  computed:{
    isValid(){
      return this.viewModel.passwordChangeValidatedResult()
    }
  }
}
</script>
<style scoped>
.desc_password{margin:10px 0 20px;font-weight:600;line-height:20px}
.desc_password .tc_primary{font-weight:700}
</style>