const guideMenuData =
[
  {
    title: 'Components',
    id: 'COMPONETS',
    menuIcon: '🧩',
    menuList: [ 
      {
        title: 'Forms',
        id: 'FORMS',
        routerPath: 'GUIDE_COMPONETS_FORMS',
      },
      {
        title: 'Tab',
        id: 'TAB',
        routerPath: 'GUIDE_COMPONETS_TAB',
      },
      {
        title: 'Search',
        id: 'SEARCH',
        routerPath: 'GUIDE_COMPONETS_SEARCH',
      },
      {
        title: 'Board',
        id: 'BOARD',
        routerPath: 'GUIDE_COMPONETS_BOARD',
      },
      {
        title: 'Table',
        id: 'TABLE',
        routerPath: 'GUIDE_COMPONETS_TABLE',
      },
      {
        title: 'category',
        id: 'CATEGORY',
        routerPath: 'GUIDE_COMPONETS_CATEGORY',
      },
      {
        title: 'Calendar',
        id: 'CALENDAR',
        routerPath: 'GUIDE_COMPONETS_CALENDAR',
      },
      {
        title: 'Buttons',
        id: 'BUTTONS',
        routerPath: 'GUIDE_COMPONETS_BUTTONS',
      },
      {
        title: 'Badges',
        id: 'BADGES',
        routerPath: 'GUIDE_COMPONETS_BADGES',
      },
      {
        title: 'Widgets',
        id: 'WIDGETS',
        routerPath: 'GUIDE_COMPONETS_WIDGETS',
      },
      {
        title: 'Step',
        id: 'STEP',
        routerPath: 'GUIDE_COMPONETS_STEP',
      },
    ]
  },
  {
    title: 'Icons',
    id: 'ICONS',
    menuIcon: 'ℹ️',
    routerPath: 'GUIDE_ICONS',
  },
  {
    title: 'Charts',
    id: 'CHARTS',
    menuIcon: '📈',
    routerPath: 'GUIDE_CHARTS',
  },
  {
    title: 'Layer',
    id: 'LAYER',
    menuIcon: '🪧',
    routerPath: 'GUIDE_LAYER',
  },
]

export { guideMenuData }