export default class NewPasswordModel {
  constructor(){
    this.oldPassword = '';
    this.newPassword = '';
    this.newRepeatPassword = '';
    this.rsaPasswordData = {
      oldPassword : '',
      newPassword : '',
      newRepeatPassword : '',
    }
  }
  getData(){
    return this.rsaPasswordData;
  }
}