
class LocalStorageManager {
  static instance;

  static get shared() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new LocalStorageManager();
    return this.instance;
  }

  // 토큰
  setToken(token) {
    // console.log('setToken')
    localStorage.setItem("token", token);
  }

  getToken() {
    // console.log('getToken')
    return localStorage.getItem("token");
  }

  clearToken() {
    // console.log('clearToken')
    localStorage.removeItem("token");
  }

  // 회원정보
  setUserData(userData) {
    // console.log('setUserData')
    localStorage.setItem("user", JSON.stringify(userData));
  }
  getUserData() {
    // console.log('getUserData')
    const str = localStorage.getItem("user")
    if( !str ) return false;
    return JSON.parse(str);
  }
  clearUserData() {
    // console.log('clearUserData')
    localStorage.removeItem("user");
  }
}

export default LocalStorageManager;
