<template>
  <div id="app">
    <router-view></router-view>
    <ToastPopup
      v-if="getIsToastPopup"
      :toastMsg="getIsToastMsg"
      @onComplete="onCompleteToast" />
    <AlertPopup
      v-if="getIsAlertPopup"
      :alertMsg="getIsAlertMsg"
      :closeBtnText="getIsCloseBtnText"
      :compeleteBtnText="getIsCompeleteBtnText"
      @onClickClose="onCloseAlert"
      @onClickComplete="onCompleteAlert" />
    <DropBoxSelect v-if="getIsSelectDropBox"/>
    <DropBoxDate v-if="getIsDateDropBox"/>
    <DropBoxTime v-if="getIsTimeDropBox"/>
    <PasswordChangePopup
      v-if="newPasswordViewModel.isPasswordChangePopup"
      :isNeedChange="true"
      :viewModel="newPasswordViewModel"
      title="비밀번호를 변경해 주세요"
      closeBtnText="취소"
      compeleteBtnText="변경"
      @onClickClose="newPasswordViewModel.onClickClosePasswordChangePopup()"
      @onClickComplete="newPasswordViewModel.onClickCompletePasswordChangePopup()"/>
  </div>
</template>

<script>
import ToastPopup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/ToastPopup';
import AlertPopup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/AlertPopup';
import DropBoxSelect from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/DropBoxSelect';
import DropBoxDate from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/DropBoxDate';
import DropBoxTime from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/DropBoxTime';
import PasswordChangePopup from '@/views/my/view/popup/PasswordChangePopup';
import { mapGetters, mapActions } from 'vuex';
import NewPasswordViewModel from '@/views/my/viewModel/NewPasswordViewModel';
import CheckSessionViewModel from '@/views/my/viewModel/CheckSessionViewModel';

export default {
  name: 'App',
  components:{
    ToastPopup,
    AlertPopup,
    DropBoxSelect,
    DropBoxDate,
    DropBoxTime,
    PasswordChangePopup,
  },
  mounted(){
    this.checkSessionViewModel.checkSession();
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.onScroll();
    window.addEventListener('scroll', this.onScroll);
    this.setIsPasswordChangeError();
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.onResize);
  },
  beforeDestroy(){
    window.addEventListener('scroll', this.onScroll);
  },
  updated(){
    this.onScroll();
  },
  data(){
    return{
      newPasswordViewModel: new NewPasswordViewModel(),
      checkSessionViewModel: new CheckSessionViewModel(),
    }
  },
  computed:{
    ...mapGetters('commonToast', [ 'getIsToastPopup','getIsToastMsg' ]),
    ...mapGetters('commonAlert', [ 'getIsAlertPopup','getIsAlertMsg','getIsCloseBtnText','getIsCompeleteBtnText', 'getConfirmCallBack' ]),
    ...mapGetters('commonDropBox', [ 'getIsSelectDropBox','getIsDateDropBox', 'getIsTimeDropBox']),
    ...mapGetters('passowrdChangeError', [ 'getIsPasswordChangeError']),
  },
  watch:{
    getIsPasswordChangeError(){
      this.setIsPasswordChangeError();
    },
  },
  methods:{
    ...mapActions('commonToast', [ 'fetchToastEnd' ]),
    ...mapActions('commonAlert', [ 'fetchAlertEnd' ]),
    ...mapActions('commonViewport', [ 'fetchViewPort', 'fetchScroll' ]),
    setIsPasswordChangeError(){
      this.newPasswordViewModel.isPasswordChangePopup = this.getIsPasswordChangeError;
      if(this.getIsPasswordChangeError){
        this.newPasswordViewModel.doneToastMsg = '비밀번호가 변경되었습니다. 재로그인해주세요'
      }
    },
    onResize(){
      this.fetchViewPort({ width: window.innerWidth, height: window.innerHeight });
    },
    onScroll() {
      this.fetchScroll(window.scrollY);
    },
    onCompleteToast() {
      this.fetchToastEnd();
    },
    onCompleteAlert(){
      this.fetchAlertEnd(true);
      if (this.getConfirmCallBack) {
        this.getConfirmCallBack();
      }
    },
    onCloseAlert(){
      this.fetchAlertEnd(false);
    }
  }
}
</script>
