const MediaQueryMixin = {
  mounted(){
    this.isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.onChangeMediaQuery();
  },
  data(){
    return{
      isDarkMode: false,
    }
  },
  methods: {
    onChangeMediaQuery(){
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      mql.onchange = (e) => {
        this.isDarkMode = e.matches;
      }
    }
  }
}
export default MediaQueryMixin;
// import MediaQueryMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/MediaQueryMixin';
// mixins: [MediaQueryMixin],